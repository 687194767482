import axios from 'axios';
import { API_URL } from './env'

const requests = {
  get: async url =>
     {
            const res = await axios.get(`${API_URL}${url}`)
            return res.data

    }
};

const HeroApi= {
  get: () =>
    { 
        return requests.get(`/landing-section`)
    },
};

const FeatureStatsApi= {
  get: () =>
    { 
        return requests.get(`/feature-stats`)
    },
};

const FeaturesApi= {
  get: () =>
    { 
        return requests.get(`/features-section`)
    },
};

const MainFeatureApi= {
  get: () =>
    { 
        return requests.get(`/main-features`)
    },
};

const TestimonialApi= {
  get: () =>
    { 
        return requests.get(`/testimonial`)
    },
};

const PricingApi= {
  get: () =>
    { 
        return requests.get(`/pricing`)
    },
};

const FAQApi= {
  get: () =>
    { 
        return requests.get(`/faqs`)
    },
};


export default {
  HeroApi,
  FeatureStatsApi,
  FeaturesApi,
  MainFeatureApi,
  TestimonialApi,
  PricingApi,
  FAQApi,
};
