import apis from 'utils/agents'
// import { API_URL } from 'utils/env'

const fetchHeroProps = async ({setHeroProps}) => {

    // heading = "Better, Faster and Cheaper Cloud.",
    // description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    // // imageSrc = "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
    // imageSrc = "https://s27389.pcdn.co/wp-content/uploads/2017/06/AdobeStock_112185177-4-1024x683.jpeg",
    // imageDecoratorBlob = true,
    // primaryButtonUrl = "https://google.com",
    // primaryButtonText = "Get Started",
    // buttonRounded = true,
    // features = ["Available in 7 Locations", "Premium Internet Backbone", "99.99% Uptime SLA"],


    const props = {}
    const data = await apis.HeroApi.get()
    if(data){
        console.log("hello", data)
        const features = data.others.split("\n")
        props.heading = data.tagline;
        props.description = data.description;
        props.imageSrc =  data.graphics.formats.medium.url;
        props.features = features
        setHeroProps(props)
        return true
    }else{
        return false
    }
}

const fetchFeatureStats = async ({setFeatureStatsProps}) => {
    // subheading = "",
    // heading = "Over 9000 Projects Completed",
    // description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    // stats = 
    // [
    //   {
    //     "key": "Clients",
    //     "value": "2500+",
    //   },
    //   {
    //     "key": "Revenue",
    //     "value": "$100M+",
    //   },
    //   {
    //     "key": "Employees",
    //     "value": "150+",
    //   },
    // ]

    const props = {}
    const data = await apis.FeatureStatsApi.get()
    if(data){
        setFeatureStatsProps(data)
        return true
    }else{
        return false
    }
}

const fetchFeatures = async ({setFeatureProps}) => {
    // cards = [
    //     {
    //         imageSrc: ShieldIconImage,
    //         title: "Secure",
    //         description: "We strictly only deal with vendors that provide top notch security."
    //       },
    //       { imageSrc: SupportIconImage, title: "24/7 Support" },
    //       { imageSrc: CustomizeIconImage, title: "Customizable" },
    //       { imageSrc: ReliableIconImage, title: "Reliable" },
    //       { imageSrc: FastIconImage, title: "Fast" },
    //       { imageSrc: SimpleIconImage, title: "Easy" }
    //     ], 
    // heading = "Amazing Features", 
    // subheading = "Features", 
    // description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." 


    const props = {}
    const data = await apis.FeaturesApi.get()
    if(data){
        await Promise.all(data.cards.map((val, index) => {
            // console.log(val)
            if(val.image){
                data.cards[index].imageSrc =  val.image.formats.thumbnail.url
            }
        }))
        setFeatureProps(data)
        return true
    }else{
        return false
    }
}

const fetchMainFeatures = async ({setMainFeatureProps}) => {
    // subheading = "Our Expertise",
    // heading = (
    //   <>
    //     Designed & Developed by <span tw="text-primary-500">Professionals.</span>
    //   </>
    // ),
    // description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    // primaryButtonText = "Learn More",
    // primaryButtonUrl = "https://timerse.com",
    // imageSrc = TeamIllustrationSrc,
    // buttonRounded = true,
    // imageRounded = true,
    // imageBorder = false,
    // imageShadow = false,
    // showDecoratorBlob = false,
    // textOnLeft = true,
    // features = [
    //     {
    //         Icon: BriefcaseIcon,
    //         title: "Professionalism",
    //         description: "We have the best professional marketing people across the globe just to work with you.",
    //         iconContainerCss: tw`bg-teal-300 text-teal-800`
    //       },
    //       {
    //         Icon: MoneyIcon,
    //         title: "Affordable",
    //         description: "We promise to offer you the best rate we can - at par with the industry standard.",
    //         iconContainerCss: tw`bg-red-300 text-red-800`
    //       }
    // ],
    // iconRoundedFull = true,
    // iconFilled = true,
    // iconContainerCss = null

    const props = {}
    const data = await apis.MainFeatureApi.get()
    if(data){
         await Promise.all(data.features.map((val, index) => {
            console.log("ferfadfdas", val)
            val.iconContainerCss = val.iconContainerCss.replace('"', "")
            // data.features[index].iconContainerCss = val.iconContainerCss.replace('"', "")
            if(val.icon){
                data.features[index].Icon =  val.icon.formats.thumbnail.url
            }
        }))
        data.imageSrc =  data.image.formats.medium.url
        setMainFeatureProps(data)
        return true
    }else{
        return false
    }
}

const fetchTestimonials = async ({setTestimonialsProps}) => {
    // imageSrc = loveIllustrationImageSrc,
    // imageRounded = true,
    // imageBorder = false,
    // imageShadow = false,
    // subheading = "Testimonials",
    // heading = "Our Clients Love Us.",
    // description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam.",
    // textOnLeft = false,
    // testimonials = [
    //   {
    //     stars: 5,
    //     profileImageSrc:
    //       "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
    //     heading: "Amazing User Experience",
    //     quote:
    //       "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
    //     customerName: "Charlotte Hale",
    //     customerTitle: "CEO, Delos Inc."
    //   },
    //   {
    //     stars: 5,
    //     profileImageSrc:
    //       "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
    //     heading: "Love the Developer Experience and Design Principles !",
    //     quote:
    //       "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    //     customerName: "Adam Cuppy",
    //     customerTitle: "Founder, EventsNYC"
    //   }
    // ]

    const props = {}
    const data = await apis.TestimonialApi.get()
    if(data){
        await Promise.all(data.testimonials.map((val, index) => {
            // console.log(val)
            if(val.profileImageRaw){
                data.testimonials[index].profileImageSrc =  val.profileImageRaw.formats.thumbnail.url
            }
        }))
        data.imageSrc =  data.imageRaw.formats.medium.url
        setTestimonialsProps(data)
        return true
    }else{
        return false
    }
}

const fetchFaqs = async ({setFaqProps}) => {
    // subheading = "FAQS",
    // heading = "You have Questions ?",
    // description = "And we have got answers to all of them. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    // faqs = [
    //   {
    //     question: "Is lunch provided free of cost ?",
    //     answer:
    //       "Yes, it is, if you have a membership with us. Otherwise it is charged as per the menu. Some limits do apply as to how much items can be included in your lunch. This limit is enough for any one person and merely exists to discourage abusal of the system."
    //   },
    //   {
    //     question: "Do you have 2 Bedroom suites ?",
    //     answer:
    //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    //   },
    //   {
    //     question: "Are Wi-Fi costs included in the price ?",
    //     answer:
    //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    //   },
    //   {
    //     question: "Where can I reach you for support ?",
    //     answer:
    //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    //   }
    // ]

    const props = {}
    const data = await apis.FAQApi.get()
    if(data){
        setFaqProps(data)
        return true
    }else{
        return false
    }
}


export default {
    fetchHeroProps,
    fetchFeatureStats,
    fetchFeatures,
    fetchMainFeatures,
    fetchTestimonials,
    fetchFaqs
}