import React, {useState, useEffect} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";


import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
// import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight.js";

import { useAsync } from 'react-async';
import helperFunctions from "utils/helperFunctions"

const HighlightedText = tw.span`text-primary-500`


export default () => {

  const [heroProps, setHeroProps] = useState({})
  const [featureStatsProps, setFeatureStatsProps] = useState({})
  const [featureProps, setFeatureProps] = useState({})
  const [mainFeatureProps, setMainFeatureProps] = useState({})
  const [testimonialsProps, setTestimonialsProps] = useState({})
  const [faqProps, setFaqProps] = useState({})

  const { data: dataHero, error: errorHero, isLoading: isLoadingHero } = useAsync({ promiseFn: helperFunctions.fetchHeroProps, setHeroProps})
  const { data: dataFs, error: errorFs, isLoading: isLoadingFs } = useAsync({ promiseFn: helperFunctions.fetchFeatureStats, setFeatureStatsProps})
  const { data: dataFea, error: errorFea, isLoading: isLoadingFea } = useAsync({ promiseFn: helperFunctions.fetchFeatures, setFeatureProps})
  const { data: dataMFea, error: errorMFea, isLoading: isLoadingMFea } = useAsync({ promiseFn: helperFunctions.fetchMainFeatures, setMainFeatureProps})
  const { data: dataTest,error:  errorTest, isLoading: isLoadingTest } = useAsync({ promiseFn: helperFunctions.fetchTestimonials, setTestimonialsProps})
  const { data: dataFaq, error: errorFaq, isLoading: isLoadingFaq } = useAsync({ promiseFn: helperFunctions.fetchFaqs, setFaqProps})

  return (
    <AnimationRevealPage disabled>
      {
        !isLoadingHero && dataHero && <Hero {...heroProps}/> 
      }
      {
        !isLoadingFs && dataFs && <FeatureStats {...featureStatsProps}/> 
      }
      {
        !isLoadingFea && dataFea && <Features {...featureProps}/> 
      }
      {
        !isLoadingMFea && dataMFea && <MainFeature {...mainFeatureProps}/> 
      }
      {
        !isLoadingTest && dataTest && <Testimonial {...testimonialsProps}/> 
      }
      {
        !isLoadingFaq && dataFaq && <FAQ {...faqProps}/> 
      }
      {/* <Testimonial 
        heading={<>Our Clients <HighlightedText>Love Us</HighlightedText></>}
      /> */}
      {/* <Pricing 
        heading={<>Flexible <HighlightedText>Plans</HighlightedText></>}
      /> */}
      {/* <FAQ
        heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
      /> */}
      <GetStarted/>
    </AnimationRevealPage>
  );
}
